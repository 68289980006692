import React, { useState, useEffect } from "react";
import Cards from "../cards/Cards";
import Select from "react-select";
import "./List.css";
import { useDispatch, useSelector } from "react-redux";
import { getVehicalsList,getBrandbymodel,getCategoryBanner } from "../../slices/listSlice";
import ReactPaginate from "react-paginate";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import MapWithInfo from "./MapWithInfo";
import Loading from "../include/Loading";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../utils/config";
import SubCategorylist from "../sliders/SubCategorylist";
import { useNavigate } from "react-router-dom";



const VehicleUserList = () => {
  //const { category_slug } = useParams();
  const {  userID } = useParams();
  // console.log("🚀 ~ file: List.js:16 ~ List ~ slug:", slug);

   const dispatch = useDispatch();
   const navigate = useNavigate();
 

  const [pageItems, setPageItems] = useState([]);


  const [brandid, setBrandId] = useState('');
 const [selectedselltype, setSelectedselltype] = useState(null);
 const [modelid, setModelId] = useState('');
 const [selectedmodeltype, setSelectedmodeltype] = useState(null);
 const [cityid, setCityId] = useState('');
 const [selectedcitytype, setSelectedcitytype] = useState(null);
 const [yearid, setYearId] = useState('');
 const [selectedyeartype, setSelectedyeartype] = useState(null);
  const [perpage, setperpage] = useState(null);
  const [totalproduct, settotalproduct] = useState(null);
  const [lastCategory, setLastCategory] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [categoryname, setCategoryname] = useState('');
  const [sortval, setSortval] = useState('');
  const [selectedsortval, setSelectedsortval] = useState(null);
  const [vehicleval, setVehicleVal] = useState('');
  const [selectedvehicleval, setSelectedvehicleval] = useState(null);
  const [price, setPrice] = useState({ min: 0, max: 0 }); // Initial price range
  const [tempPriceRange, setTempPriceRange] = useState({ min: 0, max: 0 }); // Initial temporary price range
  const [selectedPriceRange, setSelectedPriceRange] = useState({ min: -1, max: -1 }); // Selected price range
  const [brand, setbrandlist] = useState([]);
  const [model, setmodellist] = useState([]);
  const [year, setyearlist] = useState([]);
  const [city, setcitylist] = useState([]);
  const globalLocation = useSelector((state) => state.location.globalLocation);

 
  
  const vechicleArray = [{ value: 'Import', label: "Import" },{ value: 'Local', label: "Local" }];
  const subcategorylist = useSelector((state) => state.list.vehicalList.subcategories_total);


  const formatPriceLabel = (value) => {
    return `BWP${value}`;
  };

  const handlePriceChange = (value) => {
    setTempPriceRange(value);
    
  };




  const bannerimage = useSelector((state) => state.list.CategoryBanner);

  const resetStates = () => {
    setPageItems([]);
    setBrandId('');
    setModelId('');
    setYearId('');
    setCityId('');
    setSelectedmodeltype('');
    setSelectedyeartype('');
    setSelectedcitytype('');
    setSelectedsortval(null);
    setSelectedselltype(null);
    setSelectedvehicleval(null);
   
    setTempPriceRange({ min: 0, max: 0});
    setSelectedPriceRange({ min: -1, max: -1 });
  };

  const resetFilter = (e) => {
    e.preventDefault();
    dispatch(
      getVehicalsList({
        currentPage: 1,
        userid :userID,
      })
    );
   
   resetStates();  
  };

 

  const handleChangePrice = (newValue) => {
    const { min, max } = newValue; // Extracting min and max values from newValue
    setSelectedPriceRange(newValue);
    dispatch(
      getVehicalsList({
        currentPage: 1,
        brand : brandid,
        model: modelid,
        year: yearid,
        userid :userID,
        category: cityid,
        type : vehicleval,
        min_price: min, // Assigning min value to min_price
        max_price: max, // Assigning max value to max_price
        //category_slug,
      })
    );
  };
  


  useEffect(() => {
    // console.log("🚀 ~ file: ProductDetail.js:8 ~ ProductDetail ~ slug:", slug);
    dispatch(
      getVehicalsList({
        currentPage: 1,
        location: globalLocation,
        brand : brandid,
        model: modelid,
        year: yearid,
        userid :userID,
        category: cityid,
        type :vehicleval,
        min_price: selectedPriceRange.min, // Assigning min value to min_price
        max_price: selectedPriceRange.max, // Assigning max value to max_price
     })
    );// Dispatch your API call action here using slug
   
   
  }, [dispatch, userID]);

  const vehicalList = useSelector((state) => state.list.vehicalList);
  const modellist = useSelector((state) => state.list.modellist);
  const parentsubcategorylist = useSelector((state) => state.list.vehicalList.subcategories_total);
  

  useEffect(() => {
    if (
     
      vehicalList &&
      vehicalList.products &&
      vehicalList.products.list
    ) {
      
      setPageItems(vehicalList.products.list);
      settotalproduct(vehicalList.products.totalProducts);
      setperpage(vehicalList.products.perPage);
      setbrandlist(vehicalList.brands);
      setyearlist(vehicalList.year);
      setcitylist(vehicalList.city);

  
    }
   
  }, [vehicalList]);

  useEffect(() => {
    if (
      vehicalList &&
      vehicalList.products &&
      vehicalList.products.list
    ) {
      

        if (price.min === 0 && price.max === 0) {
            // console.log("Setting price from productsList");
            // console.log("productsList.price_range.min:", vehicalList.price_range.min);
            // console.log("productsList.price_range.max:", vehicalList.price_range.max);
            setPrice({
                min: Number(vehicalList.price_range.min),
                max: Number(vehicalList.price_range.max)
            });
        } else {
            console.log("Keeping existing price:", price.min, price.max);
        }

        if (selectedPriceRange.min === -1) {
           // console.log("Setting tempPriceRange from price");
            setTempPriceRange({ min: Number(price.min), max: Number(price.max) });
        } else {
           // console.log("Setting tempPriceRange from selectedPriceRange");
            setTempPriceRange(selectedPriceRange);
        }
    }
}, [vehicalList, price, selectedPriceRange]);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const cardDataCapeTown = [
    {
      image: "../img/ad/search-list-1.jpg",
      category: "I have creative tatoo making Skills!",
      kmcontent: "02.13 km Away",
    },
    {
      image: "../img/ad/search-list-2.jpg",
      category: "Royal Edition Adidas Shoes",
      kmcontent: "02.13 km Away ",
    },
    {
      image: "../img/ad/search-list-3.jpg",
      category: "Vincero Chrono S Rose Gold Men’s Watches",
      kmcontent: "02.13 km Away ",
    },
    {
      image: "../img/ad/search-list-4.jpg",
      category: "Learn First time cooking From home?",
      kmcontent: "07.93 km Away ",
    },
    {
      image: "../img/ad/search-list-5.jpg",
      category: "Vincero Chrono S Rose Gold Men’s Watches",
      kmcontent: "02.13 km Away ",
    },
    {
      image: "../img/ad/search-list-6.jpg",
      category: "Apple iPad 8th Gen Tablet, 32 GB, Space Grey",
      kmcontent: "02.13 km Away ",
    },
    {
      image: "../img/ad/search-list-7.jpg",
      category: "Voltas Semi Automatic Washing Machine",
      kmcontent: "02.13 km Away ",
    },
    {
      image: "../img/ad/search-list-8.jpg",
      category: "Voltas Semi Automatic Washing Machine",
      kmcontent: "02.13 km Away ",
    },
    {
      image: "../img/ad/search-list-9.jpg",
      category: "Voltas Semi Automatic Washing Machine",
      kmcontent: "02.13 km Away ",
    },
  ];

  const categoryoptions = [
    { value: "Category", label: "Category" },
    { value: "Automobile", label: "Automobile" },
    { value: "Home", label: "Home" },
    { value: "Electronics", label: "Electronics" },
  ];
 const UserReviewoptions = [{ value: 1, label: "Swop" },{ value: 2, label: "Sell" },{ value: 3, label: "Both" }];

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
   
    dispatch(
      getVehicalsList({
        currentPage: currentPage,
        brand : brandid,
        model: modelid,
        year: yearid,
        userid :userID,
        category: cityid,
        type:vehicleval,
       min_price: selectedPriceRange.min, // Assigning min value to min_price
        max_price: selectedPriceRange.max, // Assigning max value to max_price
      })
    );
  };

  const [markers, setMarker] = useState([]);

  useEffect(() => {
    setMarker(
      pageItems.map((item) => ({
        lat: Number(item.lat),
        lng: Number(item.lng),
        title: item.title,
      }))
    );
  }, [pageItems]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isLoading = useSelector((state) => state.list.isLoading);

  
 

  
  const transformedArray = brand.map(brand => ({
    value: brand.id,
    label: brand.name,
  }));


  const yearArray = Object.keys(year).map(key => ({
    value: key,
    label: year[key],
  }));

  const subcategoriesdrop = useSelector((state) => state.list.vehicalList.subcategories) || []; // Default to an empty array if undefined
  const cityArray = subcategoriesdrop.map(subcategory => ({
    value: subcategory.id,
    label: subcategory.name,
  }));

  const [selectedSortOption, setSelectedSortOption] = useState(null);

  
 

  const handleSelectyear = (selectedOption) => {
    // setSelectedSortOption(selectedOption);
    setYearId(selectedOption.value);
    setSelectedyeartype(selectedOption);
    dispatch(
      getVehicalsList({
        currentPage: 1,
        brand : brandid,
        year: selectedOption.value,
        userid :userID,
        model: modelid,
        category: cityid,
        type :vehicleval,
       min_price: selectedPriceRange.min, // Assigning min value to min_price
        max_price: selectedPriceRange.max, // Assigning max value to max_price
     
      })
    );

  };

  const handleSelectcity = (selectedOption) => {
    // setSelectedSortOption(selectedOption);
    setCityId(selectedOption.value);
    setSelectedcitytype(selectedOption);
    dispatch(
      getVehicalsList({
        currentPage: 1,
        brand : brandid,
        model: modelid,
        year: yearid,
        userid :userID,
        type :vehicleval,
      category: selectedOption.value,
       // city: selectedOption.value,
        min_price: selectedPriceRange.min, // Assigning min value to min_price
        max_price: selectedPriceRange.max, // Assigning max value to max_price
     
      })
    );

  };

 
  const handleSelectsell = (selectedOption) => {
    // setSelectedSortOption(selectedOption);
    setBrandId(selectedOption.value);
      setSelectedselltype(selectedOption);
    dispatch(
      getVehicalsList({
        currentPage: 1,
        brand : selectedOption.value,
       // model: selectedOption.value,
        year: yearid,
        userid :userID,
        category: cityid,
        type:vehicleval,
       min_price: selectedPriceRange.min, // Assigning min value to min_price
        max_price: selectedPriceRange.max, // Assigning max value to max_price
      })
    );

  };

  // const handleSelectsell = async (selectedOption) => {
  //   try {
  //     setBrandId(selectedOption.value);
  //     setSelectedselltype(selectedOption);
  //   const result = await dispatch(getBrandbymodel({
  //     brand_id : selectedOption.value,
  //     }));
  //   //  setResult(result);
  //     console.log('API Response:', result);
  
  //     // Check if the API call was successful
  //     if (result.payload.status === 200) {
  //       // Show success message
  //       setmodellist(result.payload.data);
  //      // window.location.reload();
  //     } else {
      
  //     }
  //   } catch (error) {
  //     // Handle any errors
     
  //   }
  // };
  
  const modelArray = model.map(model => ({
    value: model.id,
    label: model.name,
  }));

  const sortArray = [{ value: 'price-asc', label: "Price - Low to High " },{ value: 'price-desc', label: "Price - High to Low" },{ value: 'new', label: "Newest Arrival" },{ value: 'old', label: "Oldest Arrival" }];
  const handleSelectChange = (selectedOption) => {
    // setSelectedSortOption(selectedOption);
    setSortval(selectedOption.value);
    setSelectedsortval(selectedOption);
    dispatch(
      getVehicalsList({
        currentPage: 1,
        brand : brandid,
        model: modelid,
        year: yearid,
        userid :userID,
       category: cityid,
        sort : selectedOption.value,
        type:vehicleval,
       min_price: selectedPriceRange.min, // Assigning min value to min_price
        max_price: selectedPriceRange.max, // Assigning max value to max_price
     
      })
    );


  };

  const handleSelectVehicletype  = (selectedOption) => {

    setVehicleVal(selectedOption.value);
    setSelectedvehicleval(selectedOption);
    dispatch(
      getVehicalsList({
        currentPage: 1,
        brand : brandid,
        model: modelid,
        year: yearid,
        userid :userID,
        category: cityid,
        sort : sortval,
       type : selectedOption.value,
        min_price: selectedPriceRange.min, // Assigning min value to min_price
        max_price: selectedPriceRange.max, // Assigning max value to max_price
     
      })
    );
  };

  const handleBackClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
    // Navigate back to the previous page
    if (lastCategory) {
        setCategoryname(lastCategory); // Restore the last category name
        setCurrentCategory(lastCategory); // Restore the last category
    }
  
    
    navigate(-1);
  };

  const categorielist = (name, slug) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
    // Save the current category as last category before changing
    setLastCategory(currentCategory);
    setCurrentCategory(name);
    localStorage.setItem('categoryname', name); // Optionally store in local storage

    if(slug === 'dealership'){
      var url = `/listing/automobile/${slug}`;
    } else {
      var url = `/listing/byCategory/${slug}`;
    }
    navigate(url);
 
};


  return (
    <>
      <main className="sa-main-wrap">
        <Loading loading={isLoading} />
        <div className="bgtwosection">
          <div className="container">

          <div class="multi-cat-box-wrap mobile-category-wrap">
                     
                     <div class="list-of-sub-category mob-subcategory">
                        <div class="mob-list-title" onClick={handleBackClick}>
                           <div class="back-btn" >
                              <img src="../../img/back.png" alt="back button"/>
                           </div>
                           <h2 class="title-category">  
                           {localStorage.getItem("categoryname") !== '' ?  localStorage.getItem("categoryname") : ''}
                           </h2>
                        </div>
                        
                        <ul>
                        {subcategorylist &&
                subcategorylist.map((item, index) => (
                           <li key={index} onClick={() => categorielist(item.name,item.slug)}>
                              <a href="javascript:void(0);">{item.name} <i class="arrow-icon">
                              <img class="default-icon" src="/img/default-arrow.svg" alt=""/>
                              <img class="hover-icon" src="/img/default-hover-arrow.svg" alt=""/>
                              </i> </a>
                           </li>
                          ))}
                        </ul>
              
                    
                     </div>
                  </div>
       
            <section className="sa-home-banner listbanner">
              <div className="sasecouter">
                <div className="sa-banner-outer">
                  <div className="sa-home-slider">
                    <div className="home-item">
                    <a href="javascript:void(0);"  rel="noopener noreferrer">
                   <img src="../../img/list-banner1.jpg" alt="img" />
                    </a>
                    </div>
                  </div>
                </div>
                {/* {filter_type === "byCategory" ? ( */}
                <div className="cardsection sa-secure-wrap">
                  <div className="filterbywrap">
                    <h3>Filter by Price:</h3>
                    <ul className="listing-filter-ul">
                   
                      {price.min &&
                  price.max &&
                  price.min > 0 &&
                  price.max > 0 &&
                  price.min !== price.max && (
                      <>
                      <li>
                        <div className="form-group">
                      <InputRange
                      maxValue={Number(price.max)}
                      minValue={Number(price.min)}
                      formatLabel={formatPriceLabel}
                      value={tempPriceRange}
                      onChange={handlePriceChange}
                      onChangeComplete={handleChangePrice}
                      />
                        </div>
                     
                      </li> </>)}
                      <li>
                        <div className="form-group">
                          <Select
                            options={transformedArray}
                            onChange={handleSelectsell}
                            value={selectedselltype}
                            className="filterselect"
                            label="Single select"
                            isSearchable={true}
                            placeholder="Brand"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#e7e7e7"
                                  : "#e7e7e7",
                                borderRadius: "1rem",
                                boxShadow: "none",
                              }),
                            }}
                          />
                        </div>
                      </li>
                      {/* <li>
                        <div className="form-group">
                          <Select
                            options={modelArray}
                            onChange={handleSelectmodel}
                            value={selectedmodeltype}
                            className="filterselect"
                            label="Single select"
                            placeholder="Model"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#e7e7e7"
                                  : "#e7e7e7",
                                borderRadius: "1rem",
                                boxShadow: "none",
                              }),
                            }}
                          />
                        </div>
                      </li> */}
                      <li>
                        <div className="form-group">
                          <Select
                            options={yearArray}
                            onChange={handleSelectyear}
                            value={selectedyeartype}
                            className="filterselect"
                            label="Single select"
                            placeholder="Year"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#e7e7e7"
                                  : "#e7e7e7",
                                borderRadius: "1rem",
                                boxShadow: "none",
                              }),
                            }}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="form-group">
                          <Select
                            options={cityArray}
                            onChange={handleSelectcity}
                            value={selectedcitytype}
                            className="filterselect"
                            label="Single select"
                            placeholder="Dealership"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#e7e7e7"
                                  : "#e7e7e7",
                                borderRadius: "1rem",
                                boxShadow: "none",
                              }),
                            }}
                          />
                        </div>
                      </li>
                      {/* <li>
                        <div className="form-group">
                       <Select
                            options={sortArray}
                             value={selectedsortval}
                            onChange={handleSelectChange}
                            className="filterselect"
                            label="Single select"
                            placeholder="Sort By"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#e7e7e7"
                                  : "#e7e7e7",
                                borderRadius: "1rem",
                                boxShadow: "none",
                              }),
                            }}
                          />
                        </div>
                      </li> */}
                      <li>
                        <div className="form-group">
                       <Select
                            options={vechicleArray}
                             value={selectedvehicleval}
                            onChange={handleSelectVehicletype}
                            className="filterselect"
                            label="Single select"
                            placeholder="Type"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#e7e7e7"
                                  : "#e7e7e7",
                                borderRadius: "1rem",
                                boxShadow: "none",
                              }),
                            }}
                          />
                        </div>
                      </li>

                      <li className="resetfilter-li">
                <div className="btn btn-border" onClick={(e) =>resetFilter(e)}>
                  Reset all
                </div> 
                          </li>

                    </ul>
                 
                
             
                  </div>
                  {/* <div className="filterbywrap">
                <div className="small-header" onClick={(e) =>resetFilter(e)}>
                  Reset all
                </div>
             
                  </div> */}
                </div>
                {/* ) : null} */}
              </div>
            </section>
          </div>
        </div>
        <div className="sa-search-list-outer sa-comman-section">
          <div className="container">
          <SubCategorylist />
 
            <section className="sa-comman-section">
              <div className="sa-heading-section d-flex-wrap">

                  <div className="d-flex d-flex-wrap">

                <h2 className="sa-title">
                Listing in  {localStorage.getItem("categoryname") !== '' ?  localStorage.getItem("categoryname") : ''}
             </h2> 
                <div className="subdescription">
                  {vehicalList &&
                    vehicalList.products &&
                    vehicalList.products.totalProducts}{" "}
                  result found
                </div>

            </div>

            <div className="form-group ml-auto sortprice-wrap">
                       <Select
                            options={sortArray}
                             value={selectedsortval}
                            onChange={handleSelectChange}
                            className="filterselect"
                            label="Single select"
                            placeholder="Sort By"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "#e7e7e7"
                                  : "#e7e7e7",
                                borderRadius: "1rem",
                                boxShadow: "none",
                              }),
                            }}
                          />
                        </div>

              </div>
              <div className="sa-fpc-wrap">
                <div
                  id="swopcafetown"
                  className="sa-full-list-outer"
                  style={{ display: isChecked ? "none" : "flex" }}
                >
                  <div className="default-carousel-simple d-flex-wrap ">
                    {pageItems &&
                      pageItems.map((card, index) => (
                        <React.Fragment key={index}>
                        <Cards
                      key={index}
                      image={card.image}
                      title={card.name}
                      price={card.price}
                      cardtype={'automobile'}
                     kmcontent={card.city}
                     kms_driven={card.kms_driven}
                     fuel_type={card.fuel_type}
                     transmission_type={card.year}
                      slug={card.slug}
                      type={card.type}
                      type_product={card.type_product}
                    />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              
              </div>
            </section>
            {perpage < totalproduct ?
            <ReactPaginate
              previousLabel={<i className="icon-02"></i>}
              nextLabel={<i className="icon-12"></i>}
              breakLabel={"..."}
              pageCount={
                vehicalList &&
                vehicalList.products &&
                vehicalList.products.totalPages
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"sa-pagination"}
              // pageClassName={"page-item"}
              // pageLinkClassName={"page-link"}
              // previousClassName={"icon-02"}
              // previousLinkClassName={"page-link"}
              // nextClassName={"page-item"}
              // nextLinkClassName={"page-link"}
              // breakClassName={"page-item"}
              // breakLinkClassName={"page-link"}
              // activeClassName={"active-page"}
              activeLinkClassName={"active"}
            /> :''}
            {/* ReactPaginate */}

            {/* <div className="sa-pagination">
              <a href="">
                <i className="icon-02"></i>
              </a>
              <a href="" className="active">
                1
              </a>
              <a href="">2</a>
              <a href="">3</a>
              <a href="">. . .</a>
              <a href="">10</a>
              <a href="">
                <i className="icon-12"></i>
              </a>
            </div> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default VehicleUserList;
