import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocationPredictions,
  setGlobalLocation,
  setLocationChosen,
  updatelocation,
} from "../../slices/locationSlice";
import axios from "axios";
import Loading from "../include/Loading";

const LocationModel = ({ closeDiv }) => {
  const dispatch = useDispatch();

  const [userInput, setUserInput] = useState("");

  const [matchedLocations, setMatchedLocations] = useState([]);
  const [locationModal, setLocationModal] = useState({});

  useEffect(() => {
    let check = globalLocation;

    if (check && !check.city.length > 0) {
      setLocationModal({
        city: "Botswana",
        latitude: -22.3428,
        longitude: 24.6871,
      });
    } else {
    }
  }, []);

  const [selectedTopCityId, setSelectedTopCityId] = useState(null);
  const [placeId, setPlaceId] = useState("");
  const [coordinates, setCoordinates] = useState(null);

  const [currentLocation, setCurrentLocation] = useState("");

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setUserInput(value);
    localStorage.setItem('selectedlocation','');
   
    dispatch(getLocationPredictions({ value }));
  };

  const handleLocationClick = (location) => {
    setUserInput(location.description);
    setMatchedLocations([]);
    setPlaceId(location.place_id);
    getCoordinatesFromPlaceId(location.place_id, location.description);
    setSelectedTopCityId(null);
    setTimeout(() => {
      closeDiv();
    }, 400);
  };

  //!--------------OLD GET CURRENT LOCATION CAUSING ISSUES------------------------
  // const handleGetCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {

  //       console.log("..1");
  //       dispatch(
  //         getCurrentLocation({
  //           latitude: position.coords.latitude,
  //           longitude: position.coords.longitude,
  //         })
  //       );

  //       console.log("..2", currentLocation, position.coords);
  //       setLocationModal({
  //         city: currentLocation,
  //         latitude: position.coords.latitude,
  //         longitude: position.coords.longitude,
  //       });
  //       closeDiv();
  //     });
  //   } else {
  //     console.error("Geolocation is not supported by this browser");
  //   }
  // };
  //!---------------------------------------------

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      localStorage.setItem('selectedlocation','');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&components=country:BW&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.status === "OK") {
                if (data.results[0]) {
                  let city = data.results[0].address_components.find(
                    (component) =>
                      component.types.includes("locality") ||
                      component.types.includes("administrative_area_level_1")
                  );
                  if(!city){
                    city = data.results[0].address_components[0]; 
                  }
                  setCurrentLocation(city ? city.long_name : "");
                  setLocationModal({
                    city: city.long_name,
                    latitude: latitude,
                    longitude: latitude,
                  });
                  const updatedLocationModal = {
                    city: city.long_name,
                    latitude: Number(latitude),
                    longitude: Number(latitude),
                  };
                   dispatch(updatelocation(updatedLocationModal));
                  setSelectedTopCityId(null);
                  setUserInput(city.long_name);
                  setTimeout(() => {
                    closeDiv();
                  }, 400);
                }
              } else {
                throw new Error("Failed to fetch current location");
              }
            })
            .catch((error) => {
              console.error(error);
              setCurrentLocation("");
            });
        },
        (error) => {
          console.error(error);
          setCurrentLocation("");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
    }
  };

  useEffect(() => {
    setUserInput(currentLocation);
  }, [currentLocation]);

  const predictions = useSelector((state) => state.location.predictions);
 
  useEffect(() => {
    setMatchedLocations(predictions);
  }, [predictions]);

  const topCity = useSelector((state) => state.home.dashboard.city_data);

  const handleTopCity = (city) => {
    setSelectedTopCityId(city.id);
   
    setLocationModal({
      city: city.name,
      latitude: Number(city.lat),
      longitude: Number(city.lng),
    });

    const updatedLocationModal = {
      city: city.name,
      latitude: Number(city.lat),
      longitude: Number(city.lng),
    };

    //console.log("Updated Location Modal:", updatedLocationModal);
    // Store in localStorage
  localStorage.setItem('selectedlocation', JSON.stringify(updatedLocationModal));

  dispatch(setGlobalLocation(locationModal));
    dispatch(updatelocation(updatedLocationModal));
    dispatch(setLocationChosen());
    // localStorage.setItem("location", JSON.stringify(locationModal));
    setUserInput(city.name);
    setTimeout(() => {
      closeDiv();
    }, 400);
  };

  const getCoordinatesFromPlaceId = (placeId, city) => {
    const geocodeApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

    axios
      .get(geocodeApiUrl)
      .then((response) => {
        const result = response.data.results[0];
        const { lat, lng } = result.geometry.location;
        setCoordinates({ latitude: lat, longitude: lng });
        setLocationModal({ city, latitude: lat, longitude: lng });
        const updatedLocationModal = {
          city: city,
          latitude: Number(lat),
          longitude: Number(lng),
        };
        dispatch(updatelocation(updatedLocationModal));
    
        //console.log("Updated Location Modal:", updatedLocationModal);
        // Store in localStorage
      localStorage.setItem('selectedlocation', JSON.stringify(updatedLocationModal));
      })
      .catch((error) => {
        console.error("Error fetching coordinates:", error);
      });
  };

  useEffect(() => {
    if (coordinates) {
      // localStorage.setItem("location", JSON.stringify(locationModal));
      dispatch(setGlobalLocation(locationModal));
      dispatch(setLocationChosen());
    }
  }, [coordinates]);

  useEffect(() => {
    if (
      locationModal.city &&
      locationModal.latitude &&
      locationModal.longitude
    ) {
      dispatch(setGlobalLocation(locationModal));
      dispatch(setLocationChosen());

      // localStorage.setItem("location", JSON.stringify(locationModal));
    }
  }, [locationModal]);

  const globalLocation = useSelector((state) => state.location.globalLocation);
  const isLoading = useSelector((state) => state.location.isLoading);

  return (
    <div className="sa-modal-innerwrap">
      <Loading loading={isLoading} />

      <div className="sa-modal-content">
        <div className="close-modal" onClick={() => closeDiv()}>
          <span className="icon-10">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </span>
        </div>
        <div className="modal-wrap">
          <div className="logo-modal">
            <img src="../../img/logo-splash.png" alt="logo" />
          </div>
          <div className="modal-contentwrap">
            <h2>Choose your location</h2>
            {/* <p>
              Here are the some other factors that you should consider when
              choosing the best business location
            </p> */}
          </div>
          <div className="form-group location-group">
            <div className="listrowchange">
                Botswana
              <span>
                <img className="flagmob" src="../../img/flag.png" alt="flag" />
                <i className="icon-01 arrownext"></i>
              </span>
            </div>
            <div className="form-box">
              <input
                type="text"
                className="form-control"
                placeholder="Enter your Location"
                value={userInput}
                onChange={handleInputChange}
              />
            </div>
            <a className="locationinner " onClick={handleGetCurrentLocation}>
              <i className="icon-16"></i>{" "}
              <span className="txtpt">Use My Current Location</span>{" "}
              {/* {currentLocation && <p>Current Location: {currentLocation}</p>} */}
            </a>
          </div>
          {matchedLocations.length > 0 && (
            <div className="autocomplete-items">
              {matchedLocations.map((location) => (
                <div
                  key={location.place_id}
                  className="autocomplete-item"
                  onClick={() => handleLocationClick(location)}
                >
                  {location.description}
                </div>
              ))}
            </div>
          )}
          <div className="trandinglist">
            <h3>Top Cities of Botswana</h3>
            <ul>
              {topCity &&
                topCity.map((city, index) => (
                  <li
                    key={index}
                    className={selectedTopCityId === city.id ? "active" : ""}
                  >
                    <a onClick={() => handleTopCity(city)}> {city.name}</a>{" "}
                  </li>
                ))}
              {/* <li>
                {" "}
                <a href=""> Captown</a>{" "}
              </li>
              <li>
                {" "}
                <a href=""> Durban </a>{" "}
              </li>
              <li className="active">
                <a href="">Johannesburg</a>{" "}
              </li>
              <li>
                {" "}
                <a href="">Soweto</a>{" "}
              </li>
              <li>
                {" "}
                <a href="">Port Elizabeth</a>{" "}
              </li>
              <li>
                {" "}
                <a href="">Pitermaritzburg</a>{" "}
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationModel;
