import React, { useEffect, useState } from "react";
import logo from "../../img/logo-splash.png";
import DownloadSection from "./DownloadSection";
import { getSettings } from "../../slices/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import facebook from "../../img/fb.png";
import insta from "../../img/insta.png";
import twitter from "../../img/tw.png";
import { isMobile } from 'react-device-detect';

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isClassAdded, setIsClassAdded] = useState(false);
  useEffect(() => {
    dispatch(getSettings());
    setIsClassAdded(true);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(true);
  
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const settings = useSelector((state) => state.home.settings);
  const socialLinks = useSelector((state) => state.home.settings);

  const viewPage = (e, url) => {
    e.preventDefault();
    if (url === '#') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate(url);
    }
  };

  const redirectToAppStore = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const playStoreUrl = settings.googleapp_link;
    const appStoreUrl = settings.iosapp_link;

    if (/android/i.test(userAgent)) {
      window.location.href = playStoreUrl;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = appStoreUrl;
    } else {
      console.log("Unsupported device or desktop detected.");
    }
  };

  const menuData = [
    {
      title: "Company",
      items: [
        { label: "About us", url: "/content/AboutUs" },
        { label: "Faq", url: "/faq" },
        { label: "Contact us", url: "/contact-us" },
      ],
    },
    {
      title: "LegalPoints",
      items: [
        { label: "Privacy Policy", url: "/content/PrivacyPolicy" },
        { label: "Terms & Conditions", url: "/content/TermsAndConditions" },
        { label: "Cookies", url: "/content/Cookies" },
      ],
    },
  ];

  // Example rating value (this could be dynamic)
  const rating = settings.rating;  // Replace with dynamic value

  // Function to render stars based on the rating
  const renderStars = (rating) => {
    const fullStar = <img src="../../img/star-full.svg" alt="Full Star" />;
    const halfStar = <img src="../../img/star-half.svg" alt="Half Star" />;
    const emptyStar = <img src="../../img/star-gray.svg" alt="Empty Star" />;
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      if (rating >= i) {
        stars.push(fullStar);
      } else if (rating >= i - 0.5) {
        stars.push(halfStar);
      } else {
        stars.push(emptyStar);
      }
    }

    return stars;
  };

  let copyright = String.fromCodePoint(0x00a9);
  let year = new Date().getFullYear();

  return (
    <footer className="sa-footer-outer sa-home-footer">
      <DownloadSection />
      <div className="bottom-footer">
        <div className="container max-container">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="footer-logo">
                    <img src={logo} alt="logo" />
                    <span>
                      {copyright} {year} All Right Reserved
                    </span>
                  </div>
                </div>
                {menuData.map((menu, index) => (
                  <div key={index} className="col-6 col-md-3">
                    <div className="menu-list">
                      <h3>{menu.title}</h3>
                      <ul>
                        {menu.items.map((item, index) => (
                          <li key={index}>
                            <a href={item.url} onClick={(e) => viewPage(e, item.url)}>
                              {item.label}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="socila-icon">
                <ul>
                  {socialLinks.fb_url && (
                    <li className="fb-icon">
                      <a href={socialLinks.fb_url} target="_blank">
                        <img src={facebook} alt="" />
                      </a>
                    </li>
                  )}
                  {socialLinks.insta_url && (
                    <li className="insta-icon">
                      <a href={socialLinks.insta_url} target="_blank">
                        <img src={insta} alt="icon" />
                      </a>
                    </li>
                  )}
                  {socialLinks.twitter_url && (
                    <li className="twite-icon">
                      <a href={socialLinks.twitter_url} target="_blank">
                        <img src={twitter} alt="" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="poweredby-bar">
        <p>
          Website Powered & Maintained By :{" "}
          <img src="../../img/powerdbtlogo.png" alt="logo" />
        </p>
      </div>
      {isMobile && (
        <div className={`bottom-strep-wrap ${isModalOpen ? "open-it" : "d-none"}`}>
          <div className="leftstrip">
            <div className="closebtn" onClick={closeModal}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.293 1L6 5.29275L1.707 1H1V1.707L5.293 6L1 10.293V11H1.707L6 6.70701L10.293 11H11V10.293L6.70725 6L11 1.707V1H10.293Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="striplogo">
              <a href="#">
                <img src="../../img/slogo.png" alt="" />
              </a>
            </div>
            <div className="strip-content">
              <h3>{settings.footer_mobile_content}</h3>
              <div className="ratepictuer">
                <div className="starpt">
                  <ul className="startratinginner">{renderStars(rating)}</ul>
                </div>
                <p><span>{settings.download_text}</span></p>
              </div>
            </div>
          </div>
          <div className="rightstrip downloadbtn">
            <a href="javascript:void(0);" onClick={redirectToAppStore} className="btn btn-primary">Open App</a>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
