import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSignInResponse } from "../utils/authUtil";
import axios from "axios";
import { API_URL } from "../utils/config";

export const getUsers = createAsyncThunk(
  "location/getUsers",
  async ({ count, nat }, thunkAPI) => {
    // console.log({ thunkAPI });
    const { data } = await axios.get(
      `https://randomuser.me/api/?results=${count}&nat=${nat}`
    );
    return data.results;
  }
);

export const getLocationPredictions = createAsyncThunk(
  "location/getLocationPredictions",
  async ({ value }, thunkAPI) => {
    // console.log({ thunkAPI });
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${value}&components=country:BW&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );
    return data.predictions;
  }
);

export const getCurrentLocation = createAsyncThunk(
  "location/getCurrentLocation",
  async ({ latitude, longitude }, thunkAPI) => {
    // console.log({ thunkAPI });
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&components=country:BW&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );

    if (data.results[0]) {
      const city = data.results[0].address_components.find(
        (component) =>
          component.types.includes("locality") ||
          component.types.includes("administrative_area_level_1")
      );
      return city ? city.long_name : "";
    } else {
      return "No results found";
    }

    // return data.results;
  }
);

export const setGlobalLocation = createAsyncThunk(
  "location/setGlobalLocation",
  async ({ latitude, longitude, city }) => {
    const data = { latitude, longitude, city };
    return data;
  }
);


export const updatelocation = createAsyncThunk(
  "location/updatelocation",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        // "Latitude": password.latitude,
        // "Longitude": password.longitude,
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    let body = {
      latitude: postData.latitude,
      longitude: postData.longitude,
      city_name: postData.city,
     
    };
    console.log('fdss444444444',postData);
    const { data } = await axios.post(
      API_URL +
        `web-user/update-location
    `,
      body,
      config
    );
    return data;
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState: {
    isLoading: false,
    isError: false,
    data: [],
    currentLocation: "",
    predictions: [],
    globalLocation: {
      // city: "Cape Town",
      // latitude: -33.918861,
      // longitude: 18.4233,
      city: "Botswana",
      latitude: -22.3428,
      longitude: 24.6871,
    },
    hasChosenLocation: false,
  },
  reducers: {
    setLocationChosen: (state) => {
      state.hasChosenLocation = true;
    },
    // getUsersRequest: (state, action) => {
    //   state.isLoading = true;
    // },
    // getUsersSuccess: (state, action) => {
    //   state.isLoading = false;
    //   state.isError = false;
    //   state.data = action.payload;
    // },
    // getUsersError: (state, action) => {
    //   state.isLoading = false;
    //   state.isError = true;
    // }
    // setGlobalLocation: (state, action) => {
    //   state.globalLocation = action.payload;
    // },
  },

  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = false;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.isLoading = false;
      state.data = [];
      state.isError = true;
    });

    //* for getCurrentLocation
    builder.addCase(getCurrentLocation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCurrentLocation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentLocation = action.payload;
      state.isError = false;
    });

    builder.addCase(getCurrentLocation.rejected, (state) => {
      state.isLoading = false;
      state.currentLocation = "";
      state.isError = true;
    });

    //* for getLocationPredictions
    builder.addCase(getLocationPredictions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLocationPredictions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.predictions = action.payload;
      state.isError = false;
    });

    builder.addCase(getLocationPredictions.rejected, (state) => {
      state.isLoading = false;
      state.predictions = [];
      state.isError = true;
    });

    //* for setGlobalLocation
    builder.addCase(setGlobalLocation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setGlobalLocation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.globalLocation = action.payload;
      state.isError = false;
    });
    builder.addCase(setGlobalLocation.rejected, (state) => {
      state.isLoading = false;
      state.globalLocation = {};
      state.isError = true;
    });
  },
});

export const { setLocationChosen } = locationSlice.actions;

export default locationSlice.reducer;
