import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../utils/config";
import { setGlobalLocation } from "./locationSlice";
import { getSignInResponse } from "../utils/authUtil";

export const getProductDetail = createAsyncThunk(
  "productDetail/getProductDetail",
  async (slug, thunkAPI) => {
    let temp = setGlobalLocation(thunkAPI.getState());
    let userData = getSignInResponse();
    // let location = JSON.parse(localStorage.getItem("location"));

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Latitude": temp.latitude,
        "Longitude": temp.longitude,
        "user_id": userData ? userData.id :0,
      },
    };
    let body = {
      //   category_id: "anything-else",
      //   filter_type: "byCategory",
      //   subcategory_id: 0,
      //   sort_by: "FEATURED",
      //   distance: 0,
      //   rating,
      //   page: currentPage,
      //   offer_type: 0,
      //   min_price: -1,
      //   max_price: -1,
      posted_product_id: slug,
    };

    const { data } = await axios.post(
      API_URL + `web-user/product-details`,
      body,
      config
    );

    return data;
  }
);

export const addwishlistItem = createAsyncThunk(
  "productDetail/addwishlistItem",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    
    let body = {
      action: postData.action,
      posted_product_id: postData.productId,
      type : postData.type
    };
    const { data } = await axios.post(
      API_URL +
        `user/wishlist-action
    `,
      body,
      config
    );
    return data;
  }
);

export const addReport = createAsyncThunk(
  "productDetail/addReport",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    
    let body = {
      reason: postData.reason,
      posted_product_id: postData.posted_product_id,
      type :postData.type,
    };
    const { data } = await axios.post(
      API_URL +
        `user/report-swop
    `,
      body,
      config
    );
    return data;
  }
);

export const addSellerInquiry = createAsyncThunk(
  "productDetail/addSellerInquiry",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "user_id": userData ? userData.id :0,
       
      },
    };
    
    let body = {
      product_id: postData.product_id,
      name: postData.name,
      mobile_number: postData.mobile_number,
      product_user_id:  postData.product_user_id,
    };
    const { data } = await axios.post(
      API_URL +
        `web-user/business-inquiry`,
      body,
      config
    );
    return data;
  }
);

export const addPOA = createAsyncThunk(
  "productDetail/addPOA",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "user_id": userData ? userData.id :0,
       
      },
    };
    console.log('ewrwrewrwe',postData);
    let body = {
      product_id: postData.product_id,
      name: postData.name,
      email:postData.email,
      message:postData.message,
      mobile_number: postData.mobile_number,
      owner_id:  postData.product_user_id,
      user_id:userData ? userData.id :0
    };
    const { data } = await axios.post(
      API_URL +
        `web-user/poa-inquiry`,
      body,
      config
    );
    return data;
  }
);

export const activeProductaction = createAsyncThunk(
  "productDetail/activeProductaction",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    
    let body = {
      profile_user_id: postData.profile_user_id,
    };
    const { data } = await axios.post(
      API_URL +
        `user/active-products`,
      body,
      config
    );
    return data;
  }
);

export const suggestSwopAction = createAsyncThunk(
  "productDetail/suggestSwopAction",
  async (postData) => {
    let userData = getSignInResponse();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Authorization": "Bearer " + userData.auth_token,
        "user_id": userData.id,
      },
    };
    
    let body = {
      to_posted_product_id: postData.to_posted_product_id,
      from_posted_product_id: postData.from_posted_product_id
    };
    const { data } = await axios.post(
      API_URL +
        `user/suggest-swop`,
      body,
      config
    );
    return data;
  }
);

export const getVehicalDetail = createAsyncThunk(
  "vehicleDetail/getVehicalDetail",
  async (slug, thunkAPI) => {
    let temp = setGlobalLocation(thunkAPI.getState());
    let userData = getSignInResponse();
    // let location = JSON.parse(localStorage.getItem("location"));

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Latitude": temp.latitude,
        "Longitude": temp.longitude,
        "user_id": userData ? userData.id :0,
      },
    };
    let body = {
      //   category_id: "anything-else",
      //   filter_type: "byCategory",
      //   subcategory_id: 0,
      //   sort_by: "FEATURED",
      //   distance: 0,
      //   rating,
      //   page: currentPage,
      //   offer_type: 0,
      //   min_price: -1,
      //   max_price: -1,
      slug: slug,
    };

    const { data } = await axios.post(
      API_URL + `web-user/vehicle-details`,
      body,
      config
    );

    return data;
  }
);

export const getlistPoa = createAsyncThunk(
  "productDetail/getlistPoa",
  async (posted_product_id, thunkAPI) => {
    let temp = setGlobalLocation(thunkAPI.getState());
    let userData = getSignInResponse();
    // let location = JSON.parse(localStorage.getItem("location"));

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x_rest_username": "admin@restuser",
        "x_rest_password": "admin@Access",
        "x_rest_version": "v1",
        "Latitude": temp.latitude,
        "Longitude": temp.longitude,
        "user_id": userData ? userData.id :0,
      },
    };
   
    let body = {
      product_id: posted_product_id,
      user_id:userData ? userData.id :0
    };

    const { data } = await axios.post(
      API_URL + `web-user/list-poa`,
      body,
      config
    );

    return data;
  }
);

const productDetailSlice = createSlice({
  name: "productDetail",
  initialState: {
    isLoading: false,
    isError: false,
    details: {},
    vehicals: {},
    POAlist:{}
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getProductDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.details = action.payload.data;
      // state.productsData = action.payload.data.products;
      state.isError = false;
    });
    builder.addCase(getProductDetail.rejected, (state) => {
      state.isLoading = false;
      state.details = {};
      state.isError = true;
    });

    builder.addCase(getVehicalDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVehicalDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vehicals = action.payload.data;
      // state.productsData = action.payload.data.products;
      state.isError = false;
    });
    builder.addCase(getVehicalDetail.rejected, (state) => {
      state.isLoading = false;
      state.vehicals = {};
      state.isError = true;
    });

    builder.addCase(getlistPoa.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getlistPoa.fulfilled, (state, action) => {
      state.isLoading = false;
      state.POAlist = action.payload.data;
      // state.productsData = action.payload.data.products;
      state.isError = false;
    });
    builder.addCase(getlistPoa.rejected, (state) => {
      state.isLoading = false;
      state.POAlist = {};
      state.isError = true;
    });
    
  },
});

export default productDetailSlice.reducer;
